html,
body {
  font-family: tahoma;
  background-color: #F0F0F0;
}

/********** navbar styles **********/
nav {
  z-index: 10;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

nav ul {
  margin: 0;
}

nav li {
  display: inline;
  padding-right: 1.5vw;
  font-size: 1.5vw;
}

nav a {
  text-decoration: none;
  color: #000;
}

nav a:hover {
  text-decoration: underline;
}

/********** Home page styles **********/
.App {
  text-align: center;
}

.App-header {
  background: url(images/splash-asset.jpg) no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  max-width: 100%;
  padding-top: 56.9%;
  margin-top: -1px;
  text-align: center;
  position: relative;
}

.splash-text {
  position: absolute;
  top: 39%;
  vertical-align: middle;
  margin: auto;
  width: 100%;
  font-size: 6vw;
  color: #fff;
}

.blurb-one {
  position: absolute;
  top: 15%;
  left: 10%;
  font-size: 1.5vw;
  color: #fff;
}

.blurb-two {
  position: absolute;
  top: 25%;
  right: 10%;
  font-size: 1.5vw;
  color: #fff;
}

.blurb-three {
  position: absolute;
  top: 75%;
  right: 30%;
  font-size: 1.5vw;
  color: #fff;
}

@keyframes flickerAnimation {
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}

@-o-keyframes flickerAnimation{
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}

@-moz-keyframes flickerAnimation{
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}

@-webkit-keyframes flickerAnimation{
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}

.blurb-one {
   -webkit-animation: flickerAnimation 10s infinite;
   -moz-animation: flickerAnimation 10s infinite;
   -o-animation: flickerAnimation 10s infinite;
    animation: flickerAnimation 10s infinite;
}

.blurb-two {
   -webkit-animation: flickerAnimation 3s infinite;
   -moz-animation: flickerAnimation 3s infinite;
   -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
}


.blurb-three {
   -webkit-animation: flickerAnimation 5s infinite;
   -moz-animation: flickerAnimation 5s infinite;
   -o-animation: flickerAnimation 5s infinite;
    animation: flickerAnimation 5s infinite;
}

.movieButton {
  position: absolute;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 3px;
  height: 1.5vw;
  z-index: 11;
  top: .5vw;
  left: .5vw;
  color: #fff;
  font-size: 1vw;
}

.movieButton:hover {
  background-color: #fff;
  color: #000;
}

/********** Resume page styles **********/
.resume-div {
  margin-top: 25px;
  min-width: 485px;
}

/********** Contact page styles **********/
.contact-header {
  margin-top: 6vw;
  font-size: 4vw;
}

.contact-text {
  width: 50%;
  margin: .5vw auto;
}

#contact-form {
  margin: 2vw auto 0 auto;
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: left;
}

#contact-form label {
  margin-bottom: .3vw;
}

#contact-form input {
  height: 24px;
  margin-bottom: 1.5vw;
  font-size: 1.5vw;
}

#contact-form textarea {
  margin-bottom: 2vw;
  font-size: 1.5vw;
}

#contact-form textarea {
  resize: none;
  height: 15vw;
}

#contact-submit {
  border: none;
  border-radius: 3px;
  font-size: 2vw;
  background-color: darkslategrey;
  color: #fff;
  cursor: pointer;
}